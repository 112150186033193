const KNOWN_COLORS = [
  "green",
  "light-green",
  "medium-green",
  "white",
  "light-blue",
  "reset",
] as const;

type KnownColor = (typeof KNOWN_COLORS)[number];

export function useBodyBackground(color: KnownColor | string | undefined) {
  const known = KNOWN_COLORS.find((c) => c === color);
  const attrs = {
    class: known ? [`bg-${known}`] : [color, "body-bg"].filter(truthy),
  };

  function removeOldClasses(it: string, _: number, parent: DOMTokenList) {
    if (!attrs.class.includes(it)) {
      parent.remove(it);
    }
  }

  onMounted(() => {
    // TODO: Sometimes, if you navigate before all the scripts are loaded, the body class is not removed by useHead, even though it should, so we do it manually here.
    document.body.classList.forEach(removeOldClasses);
    document.querySelector("html")?.classList.forEach(removeOldClasses);
  });

  useHead({
    bodyAttrs: attrs,
    htmlAttrs: attrs,
  });
}
